.home {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.homeSection {
  background-image: url('/public/img/background/soldier-poppies.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -225px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.poppyLogo {
  width: 5vw;
}

h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 100;
  z-index: 10;
  opacity: .8;
  font-family: 'Risthiya Brush', sans-serif;
  /* font-family: 'Nightglow', sans-serif; */
  /* font-family: 'Meldina', sans-serif; */
  /* margin-bottom: 15vh; */
}

@media (width <= 500px) {
  .homeSection {
    background-position: -220px center;
  }
  .poppyLogo {
    width: 15vw;
  }
}

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
